html, body, p, div, h1, h2, h3, h4, h5, h6, ul, ol, dl, img, pre, form, fieldset {
  margin: 0;
  padding: 0;
}

img, fieldset {
  border: 0;
}

body, html {
  width: 100%;
  height: 100%;
}

body {
  background-color: var(--ds-surface, #fff);
  color: var(--ds-text, #172b4d);
  font: var(--ds-font-body, normal 400 14px / 1.42857 -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif);
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

p, ul, ol, dl, h1, h2, h3, h4, h5, h6, blockquote, pre, form, table {
  margin: var(--ds-space-150, 12px) 0 0 0;
}

a {
  color: var(--ds-link, #0052cc);
  text-decoration: none;
}

a:hover {
  color: var(--ds-link, #0065ff);
  text-decoration: underline;
}

a:active {
  color: var(--ds-link-pressed, #0747a6);
}

a:focus-visible {
  outline: var(--ds-border-width-outline, 2px) solid var(--ds-border-focused, #2684ff);
  outline-offset: var(--ds-space-025, 2px);
}

@supports not selector(*:focus-visible) {
  a:focus {
    outline: var(--ds-border-width-outline, 2px) solid var(--ds-border-focused, #4c9aff);
    outline-offset: var(--ds-space-025, 2px);
  }
}

h1 {
  font: var(--ds-font-heading-xlarge, 600 2.07143em / 1.10345 -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif);
  letter-spacing: -.01em;
  color: var(--ds-text);
  margin-top: var(--ds-space-500);
}

h2 {
  font: var(--ds-font-heading-large, 500 1.71429em / 1.16667 -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif);
  letter-spacing: -.01em;
  color: var(--ds-text);
  margin-top: var(--ds-space-500);
}

h3 {
  font: var(--ds-font-heading-medium, 500 1.42857em / 1.2 -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif);
  letter-spacing: -.008em;
  color: var(--ds-text);
  margin-top: 28px;
}

h4 {
  font: var(--ds-font-heading-small, 600 1.14286em / 1.25 -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif);
  letter-spacing: -.006em;
  color: var(--ds-text);
  margin-top: var(--ds-space-300);
}

h5 {
  font: var(--ds-font-heading-xsmall, 600 1em / 1.14286 -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif);
  letter-spacing: -.003em;
  color: var(--ds-text);
  margin-top: var(--ds-space-200);
}

h6 {
  font: var(--ds-font-heading-xxsmall, 600 .857143em / 1.33333 -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif);
  color: var(--ds-text);
  margin-top: var(--ds-space-250);
  text-transform: uppercase;
}

ul, ol, dl {
  padding-left: var(--ds-space-500, 40px);
}

dd, dd + dt, li + li, ul ul:not(:first-child), ol ul:not(:first-child), ul ol:not(:first-child), ol ol:not(:first-child) {
  margin-top: var(--ds-space-050, 4px);
}

p:first-child, ul:first-child, ol:first-child, dl:first-child, h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child, blockquote:first-child, pre:first-child, form:first-child, table:first-child {
  margin-top: 0;
}

blockquote, q {
  color: inherit;
}

blockquote {
  padding-left: var(--ds-space-500, 40px);
  border: none;
}

[dir="rtl"] blockquote {
  padding-left: 0;
  padding-right: var(--ds-space-500, 40px);
}

blockquote:before, q:before {
  content: "“";
}

blockquote:after, q:after {
  content: "”";
}

blockquote:before {
  float: left;
  text-align: right;
  width: 1em;
  margin-left: -1em;
}

[dir="rtl"] blockquote:before {
  float: right;
  text-align: left;
  margin-right: -1em;
}

blockquote > :last-child {
  display: inline-block;
}

small {
  font: var(--ds-font-body-small, normal 400 11px / 16px ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, system-ui, "Helvetica Neue", sans-serif);
}

code, kbd {
  font-family: var(--ds-font-family-code, ui-monospace, Menlo, "Segoe UI Mono", "Ubuntu Mono", monospace);
}

var, address, dfn, cite {
  font-style: italic;
}

abbr {
  border-bottom: 1px var(--ds-border, #ccc) dotted;
  cursor: help;
}

@supports (color-scheme: dark) and (color-scheme: light) {
  [data-color-mode="light"] {
    --lightningcss-light: initial;
    --lightningcss-dark: ;
    color-scheme: light;
  }

  [data-color-mode="dark"] {
    --lightningcss-light: ;
    --lightningcss-dark: initial;
    color-scheme: dark;
  }
}

table {
  border-collapse: collapse;
  width: 100%;
}

thead, tbody, tfoot {
  border-bottom: 2px solid var(--ds-border, #dfe1e6);
}

td, th {
  padding: var(--ds-space-050, 4px) var(--ds-space-100, 8px);
  text-align: left;
  border: none;
}

th {
  vertical-align: top;
}

td:first-child, th:first-child {
  padding-left: 0;
}

td:last-child, th:last-child {
  padding-right: 0;
}

caption {
  font: var(--ds-font-heading-medium, 500 1.42857em / 1.2 -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif);
  letter-spacing: -.008em;
  color: var(--ds-text);
  margin-top: 28px;
  margin-bottom: var(--ds-space-100, 8px);
  text-align: left;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section {
  display: block;
}

@-moz-document url-prefix() {
  img {
    font-size: 0;
  }

  img:-moz-broken {
    font-size: inherit;
  }
}

button {
  font-family: inherit;
}

.assistive {
  clip: rect(1px, 1px, 1px, 1px) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}
/*# sourceMappingURL=index.c068d229.css.map */
