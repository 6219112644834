html,
body,
p,
div,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dl,
img,
pre,
form,
fieldset {
	margin: 0;
	padding: 0;
}
img,
fieldset {
	border: 0;
}

body,
html {
	height: 100%;
	width: 100%;
}

body {
	background-color: var(--ds-surface, #fff);
	color: var(--ds-text, #172b4d);
	font: var(
		--ds-font-body,
		normal 400 14px/1.42857142857143 -apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		'Roboto',
		'Oxygen',
		'Ubuntu',
		'Fira Sans',
		'Droid Sans',
		'Helvetica Neue',
		sans-serif
	);
	-ms-overflow-style: -ms-autohiding-scrollbar;
	text-decoration-skip-ink: auto;
}

/* Default margins */
p,
ul,
ol,
dl,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
pre,
form,
table {
	margin: var(--ds-space-150, 12px) 0 0 0;
}

/* Links */
a {
	color: var(--ds-link, #0052cc);
	text-decoration: none;
}
a:hover {
	color: var(--ds-link, #0065ff);
	text-decoration: underline;
}
a:active {
	color: var(--ds-link-pressed, #0747a6);
}
a:focus-visible {
	outline: var(--ds-border-width-outline, 2px) solid var(--ds-border-focused, #2684ff);
	outline-offset: var(--ds-space-025, 2px);
}
@supports not selector(*:focus-visible) {
	a:focus {
		outline: var(--ds-border-width-outline, 2px) solid var(--ds-border-focused, #4c9aff);
		outline-offset: var(--ds-space-025, 2px);
	}
}
/* Headings */
h1 {
	font: var(
		--ds-font-heading-xlarge,
		600 2.0714285714285716em/1.103448275862069 -apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		'Roboto',
		'Oxygen',
		'Ubuntu',
		'Fira Sans',
		'Droid Sans',
		'Helvetica Neue',
		sans-serif
	);
	letter-spacing: -0.01em;
	color: var(--ds-text);
	margin-top: var(--ds-space-500);
}
h2 {
	font: var(
		--ds-font-heading-large,
		500 1.7142857142857142em/1.1666666666666667 -apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		'Roboto',
		'Oxygen',
		'Ubuntu',
		'Fira Sans',
		'Droid Sans',
		'Helvetica Neue',
		sans-serif
	);
	letter-spacing: -0.01em;
	color: var(--ds-text);
	margin-top: var(--ds-space-500);
}
h3 {
	font: var(
		--ds-font-heading-medium,
		500 1.4285714285714286em/1.2 -apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		'Roboto',
		'Oxygen',
		'Ubuntu',
		'Fira Sans',
		'Droid Sans',
		'Helvetica Neue',
		sans-serif
	);
	letter-spacing: -0.008em;
	color: var(--ds-text);
	margin-top: 28px;
}
h4 {
	font: var(
		--ds-font-heading-small,
		600 1.1428571428571428em/1.25 -apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		'Roboto',
		'Oxygen',
		'Ubuntu',
		'Fira Sans',
		'Droid Sans',
		'Helvetica Neue',
		sans-serif
	);
	letter-spacing: -0.006em;
	color: var(--ds-text);
	margin-top: var(--ds-space-300);
}
h5 {
	font: var(
		--ds-font-heading-xsmall,
		600 1em/1.1428571428571428 -apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		'Roboto',
		'Oxygen',
		'Ubuntu',
		'Fira Sans',
		'Droid Sans',
		'Helvetica Neue',
		sans-serif
	);
	letter-spacing: -0.003em;
	color: var(--ds-text);
	margin-top: var(--ds-space-200);
}
h6 {
	font: var(
		--ds-font-heading-xxsmall,
		600 0.8571428571428571em/1.3333333333333333 -apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		'Roboto',
		'Oxygen',
		'Ubuntu',
		'Fira Sans',
		'Droid Sans',
		'Helvetica Neue',
		sans-serif
	);
	color: var(--ds-text);
	margin-top: var(--ds-space-250);
	text-transform: uppercase;
}

/* Lists */
ul,
ol,
dl {
	padding-left: var(--ds-space-500, 40px);
}

dd,
dd + dt,
li + li {
	margin-top: var(--ds-space-050, 4px);
}
ul ul:not(:first-child),
ol ul:not(:first-child),
ul ol:not(:first-child),
ol ol:not(:first-child) {
	margin-top: var(--ds-space-050, 4px);
}

/* remove top margin for first element */
p:first-child,
ul:first-child,
ol:first-child,
dl:first-child,
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child,
blockquote:first-child,
pre:first-child,
form:first-child,
table:first-child {
	margin-top: 0;
}

/* Quotes */
blockquote,
q {
	color: inherit;
}
blockquote {
	border: none;
	padding-left: var(--ds-space-500, 40px);
}
[dir='rtl'] blockquote {
	padding-left: 0;
	padding-right: var(--ds-space-500, 40px);
}

blockquote::before,
q::before {
	content: '\201C';
}

blockquote::after,
q::after {
	content: '\201D';
}

blockquote::before {
	float: left;
	/* to keep the quotes left of any child elements like blockquote > p */
	margin-left: -1em;
	text-align: right;
	width: 1em;
}
[dir='rtl'] blockquote::before {
	float: right;
	margin-right: -1em;
	text-align: left;
}

blockquote > :last-child {
	display: inline-block; /* so the quotes added via pseudos follow it immediately. */
}

/* Other typographical elements */
small {
	font: var(
		--ds-font-body-small,
		normal 400 11px/16px ui-sans-serif,
		-apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		Ubuntu,
		system-ui,
		'Helvetica Neue',
		sans-serif
	);
}

code,
kbd {
	font-family: var(
		--ds-font-family-code,
		ui-monospace,
		Menlo,
		'Segoe UI Mono',
		'Ubuntu Mono',
		monospace
	);
}

var,
address,
dfn,
cite {
	font-style: italic;
}

abbr {
	border-bottom: 1px var(--ds-border, #ccc) dotted;
	cursor: help;
}

@supports (color-scheme: dark) and (color-scheme: light) {
	[data-color-mode='light'] {
		color-scheme: light;
	}
	[data-color-mode='dark'] {
		color-scheme: dark;
	}
}

table {
	border-collapse: collapse;
	width: 100%;
}

thead,
tbody,
tfoot {
	border-bottom: 2px solid var(--ds-border, #dfe1e6);
}

td,
th {
	border: none;
	padding: var(--ds-space-050, 4px) var(--ds-space-100, 8px);
	text-align: left;
}

th {
	vertical-align: top;
}

td:first-child,
th:first-child {
	padding-left: 0;
}

td:last-child,
th:last-child {
	padding-right: 0;
}

caption {
	font: var(
		--ds-font-heading-medium,
		500 1.4285714285714286em/1.2 -apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		'Roboto',
		'Oxygen',
		'Ubuntu',
		'Fira Sans',
		'Droid Sans',
		'Helvetica Neue',
		sans-serif
	);
	letter-spacing: -0.008em;
	color: var(--ds-text);
	margin-top: 28px;
	margin-bottom: var(--ds-space-100, 8px);
	text-align: left;
}

/* IE11 and some older browsers don't support these elements yet and treat them as display: inline; */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
	display: block;
}

/* Suppress the ugly broken image styling in Firefox */
@-moz-document url-prefix() {
	img {
		font-size: 0;
	}
	img:-moz-broken {
		font-size: inherit;
	}
}

/*
  Chrome 83.0.4103.61 introduced a new user-agent style that broke all buttons for us. This default override reverts it.
  chrome user agent added this => font: 400 13.3333px Arial;
*/
button {
	font-family: inherit;
}

.assistive {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	height: 1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;
}
